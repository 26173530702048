import { initializeApp, deleteApp } from 'firebase/app';
import {
  applyActionCode,
  browserLocalPersistence,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  linkWithPopup,
  linkWithRedirect,
  onAuthStateChanged,
  RecaptchaVerifier,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut,
  unlink,
  updateEmail,
  updateProfile,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const app = initializeApp(config);

const google_provider = new GoogleAuthProvider();
const facebook_provider = new FacebookAuthProvider();
const emailAuth = (email, password) =>
  EmailAuthProvider.credential(email, password);

const authHelper = {
  applyActionCode,
  browserLocalPersistence,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  emailAuth,
  facebook_provider,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  getRedirectResult,
  google_provider,
  GoogleAuthProvider,
  linkWithPopup,
  linkWithRedirect,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut,
  unlink,
  updateEmail,
  updateProfile,
  verifyPasswordResetCode,
};

const recaptcha = (elm, config) => {
  return new RecaptchaVerifier(authRef, elm, config);
};

const functionsRef = getFunctions(app);
const persistenceLocal = browserLocalPersistence;
const authRef = getAuth(app);

const dbRef = getFirestore(app);
const dbHelper = { doc, onSnapshot };

export {
  app,
  authHelper,
  authRef,
  dbHelper,
  dbRef,
  deleteApp,
  emailAuth,
  facebook_provider,
  functionsRef,
  httpsCallable,
  google_provider,
  persistenceLocal,
  recaptcha,
};
