import Cookies from 'js-cookie';
import * as Sentry from '@sentry/browser';
import { api } from 'actions';
import { authRef } from 'firebase-config/firebase';
import {
  APPS_DOMAIN,
  AUTH_COOKIE_NAME,
  AUTH_COOKIE_NAME_OLD,
} from 'constants/const';

/**
 * Set loginTokenSession cookie helper.
 *
 * @returns
 */
export const setLoginTokenSession = () => {
  if (!authRef?.currentUser?.refreshToken) {
    const error = new Error('setLoginTokenSession used for non logged in user');
    console.error(error.message);
    Sentry.captureException(error);
    return false;
  }

  return api.setToken(authRef?.currentUser?.refreshToken).then(({ data }) => {
    if (data?.data?.token) {
      const options = {
        expires: 1,
        path: '/',
        domain: `.${APPS_DOMAIN}`,
      };
      // Set loginTokenSession cookie.
      Cookies.set(AUTH_COOKIE_NAME, data?.data?.token, options);
      // Set customToken legacy cookie.
      Cookies.set(AUTH_COOKIE_NAME_OLD, data?.data?.token, options);
    }
    return false;
  });
};
