import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { db } from 'actions';
import { dbRef, dbHelper } from 'firebase-config/firebase';

/**
 * Helper hook to handle Segment page event processing.
 */
export const useGetAccountData = (field) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const [accountDataLoaded, setAccountDataLoaded] = useState(false);

  useEffect(() => {
    // Check field value only for logged in user.
    if (authUser?.uid) {
      // Listen to veterans collection changes.
      dbHelper.onSnapshot(
        dbHelper.doc(dbRef, 'veterans', authUser.uid),
        (doc) => {
          const data = doc.data();
          // Check if field is available.
          if (data?.[field]) {
            dispatch(db.fetchAccountInfo());
            setAccountDataLoaded(true);
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [authUser]);

  return [accountDataLoaded];
};
